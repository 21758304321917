import 'babel-polyfill';
import { debounce } from '@zxcvbn-ts/core';

(function ($) {
    'use strict';

    let currentScore = 0;

    // Because the zxcvbn library is huge it is not always loaded, if
    // unavailable skip performing password strength measurements.
    async function zxcvbn(password, userInputs) {
        if ('zxcvbn' in window) {
            return await window.zxcvbn(password, userInputs);
        }

        return {};
    }

    function resetPasswordStrength($target) {
        $target.text('');

        $target.removeClass('password-strength-0');
        $target.removeClass('password-strength-1');
        $target.removeClass('password-strength-2');
        $target.removeClass('password-strength-3');
        $target.removeClass('password-strength-4');
    }

    async function updatePasswordStrength() {
        var $this = $(this);
        var $target = $($this.data('password-strength'));
        var $userInputs = $($this.data('password-strength-inputs'));
        var pw = $this.val();
        var result;

        if (pw.length === 0) {
            resetPasswordStrength($target);

            return;
        }

        result = await zxcvbn(pw, $userInputs.map(function() {
            return $(this).val();
        }).get());

        resetPasswordStrength($target);

        if (result.score === undefined) {
            return;
        }

        if ((result.feedback.warning || '').length > 0) {
            $target.text(result.feedback.warning);
        }

        // Suggestions look a bit naff, so disabled for the moment.
        // if (result.feedback.suggestions.length > 0) {
        //     $target.append($('<ul>').append(result.feedback.suggestions.map(function(value) {
        //         return $('<li>').text(value);
        //     })));
        // }

        $target.addClass('password-strength-'+result.score);
        currentScore = result.score;
    }

    $('[data-password-strength]')
        .on('keyup', debounce(updatePasswordStrength, 50))
        // Apply on page load
        .each(updatePasswordStrength);

    $('[data-password-strength]').parents('form').on('submit', function(e) {
        let $this = $(this),
            $passwordStrengthEl = $('[data-password-strength]'),
            pw = $passwordStrengthEl.val();

        // Recheck password score since the password boxes can change without a keyup event firing (due to password generators)
        $passwordStrengthEl.each(updatePasswordStrength);

        if (currentScore < 3 && 'zxcvbn' in window) {
            let $helpBlock = $passwordStrengthEl.siblings('.help-block');
            // The error message doesn't already exist
            if ($helpBlock.text().indexOf($passwordStrengthEl.data('too-weak')) < 0) {
                if ($helpBlock.length) {
                    // We have an existing error block showing, so append to it
                    $helpBlock.children('ul').append(
                        `<li><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span class="sr-only">Error:</span> ${$passwordStrengthEl.data('too-weak')}</li>`
                    );
                } else {
                    // Generate the block and add it instead
                    $passwordStrengthEl.after(
                        `<div class="help-block"><ul class="list-unstyled"><li><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span class="sr-only">Error:</span> ${$passwordStrengthEl.data('too-weak')}</li></ul></div>`
                    );
                }
            }

            $passwordStrengthEl.parents('.form-group').addClass('has-error');

            e.preventDefault();
        }
    });
})(jQuery);

(function ($) {
    'use strict';

    var $endDate = $('.js-claim-reduce-period-endDay');
    var $toLabel = $('.js-claim-reduce-period-to-label');

    $endDate.on('change', function() {
        if (this.value == '') {
            $toLabel.text('');
        } else {
            $toLabel.text($(this).children('option:selected').data('display'));
        }
    });
})(jQuery);

const key = document.body.getAttribute('data-ga-account'),
    route = document.body.getAttribute('data-ga-page-url'),
    referrerRoute = document.body.getAttribute('data-ga-referrer-url'),
    domain = document.body.getAttribute('data-ga-domain');

if (
    key
    && !(window.doNotTrack == "1" || navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1")
) {
    const gtagGlobalName = 'gtagData';

    // Define the tag manager dataLayer object, note custom name.
    window[gtagGlobalName] = [];

    gtag = function() {
        window[gtagGlobalName].push(arguments);
    };

    (function (key) {
        const elem = document.createElement('script');
        const firstScript = document.getElementsByTagName('script')[0];

        elem.async = true;
        elem.src = 'https://www.googletagmanager.com/gtag/js?l='+encodeURIComponent(gtagGlobalName)+'&id='+encodeURIComponent(key);

        firstScript.parentNode.insertBefore(elem, firstScript);
    })(key);

    // Setup GA and log page hit
    if (!/StatusCake/iu.test(navigator.userAgent)) {
        // Sanitise URLs to exclude any user data.
        const pageUrl = new URL('/'+route, window.location.origin);
        let referrerUrl;

        if (referrerRoute) {
            referrerUrl = new URL('/'+referrerRoute, window.location.origin);
        } else {
            if (document.referrer) {
                // Possibly an external referrer or couldn't figure out the route,
                // just use the referrer without any path/query/hash.
                referrerUrl = new URL(document.referrer);

                referrerUrl.pathname = '/';
                referrerUrl.search = '';
                referrerUrl.hash = '';
            }
        }

        gtag('js', new Date());
        gtag('set', 'page_location', pageUrl.toString());
        gtag('set', 'page_title', 'NMRU');
        gtag('set', 'page_referrer', referrerUrl !== undefined ? referrerUrl.toString() : '');
        gtag('config', key);
    }
}

(function ($) {
    'use strict';

    if ($('body.js-faq').length) {
        var $answers = $('.panel-collapse');
        $answers.collapse('hide');
        $answers.css('height', 'auto');

        var hash = window.location.hash;
        if (hash.length > 0) {
            var $target = $(hash);
            $target.collapse('show');
            $('html, body').animate({
                scrollTop: $target.offset().top - 40
            }, 500);
        }
    }
})(jQuery);

(function ($) {
    'use strict';

    $('.js-setting-filter').on('change keyup', function(){
        var $this = $(this),
            $settingRow = $('.js-setting-row');

        if ($this.val == '') {
            $settingRow.removeClass('hide');
        } else {
            $settingRow
                .addClass('hide')
                .filter(function() {
                    return (this.textContent || $(this).text()).toUpperCase().indexOf($this.val().toUpperCase()) >= 0;
                })
                .removeClass('hide');
        }
    });
})(jQuery);

// GDS makes their error summary accessible-friendly by putting keyboard focus
// to it on page load. Methods here are largely paraphrased from the GDS framework.
// https://github.com/alphagov/govuk-frontend/blob/main/packages/govuk-frontend/src/govuk/components/error-summary/error-summary.mjs
document.addEventListener('DOMContentLoaded', function() {
    const alertElement = document.querySelector('[role="alert"]');

    if (undefined !== alertElement && null !== alertElement) {
        const skipLink = document.querySelector('.skip-link');

        if (document.activeElement === skipLink) {
            alertElement.setAttribute('tabindex', '-1');

            alertElement.addEventListener('blur', function(){
                alertElement.removeAttribute('tabindex');
            });

            alertElement.focus();
        }
    }
});

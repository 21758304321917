require('../css/app.less');

require('bootstrap');

require('./../../../../../Resources/public/js/globals');
require('./actionPending');
require('./errorSummary');
require('./changeCertificate');
require('./claimReducePeriod');
require('./completeRegistration');
require('./faq');
require('./googleAnalytics');
require('./passwordStrength');
require('./pdfEmbed');
require('./settingSelectFilter');
require('./toggleTweak');

(function ($) {
    'use strict';

    var button = $('.js-action-pending');

    if (button.is('a')) {
        button.on('click', function(){
            $(this).addClass('pending');
        });
    } else {
        button.parents('form').on('submit', function() {
            $(this).find(button).addClass('pending');
        });
    }
})(jQuery);

const ConditionalDisableFields = require('./../../../../../Resources/public/js/ConditionalDisableFields');

(function ($, ConditionalDisableFields) {
    'use strict';

    var $registrationAuthorityNone = $('input[name="certificate[registrationAuthority]"][value="NONE"]');
    if ($registrationAuthorityNone.length) {
        var $registrationAuthorityOther = $('input[name="certificate[registrationAuthority]"][value!="NONE"]');
        new ConditionalDisableFields($registrationAuthorityNone, $registrationAuthorityOther, [
            '#certificate_registrationNumber'
        ]);
    }

    var $registrationAuthorityDfe = $('input[name="certificate[registrationAuthority]"][value="DEPT_EDUCATION"]');
    var $registrationAuthorityNoCert = $registrationAuthorityNone.add($registrationAuthorityDfe);
    if ($registrationAuthorityNoCert.length) {
        var $registrationAuthorityOfsted = $('input[name="certificate[registrationAuthority]"][value="OFSTED"]');
        var $registrationAuthorityWales = $('input[name="certificate[registrationAuthority]"][value="WALES_INSPECTORATE"]');
        var $registrationAuthorityCert = $registrationAuthorityOfsted.add($registrationAuthorityWales);
        new ConditionalDisableFields($registrationAuthorityNoCert, $registrationAuthorityCert, [
            '#certificate_file'
        ]);
    }

})(jQuery, ConditionalDisableFields);
